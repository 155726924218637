<template>
  <Page title="Maturaarbeit(en)" color="success">
    <template slot="help">
      Du hast hier Zugriff auf deine Maturarbeit(en). Du kannst:
      <v-list dense>
        <v-list-item>
          <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
          <v-list-item-content>Arbeit/Titel bearbeiten</v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$_hasRole('teacher')">
          <v-list-item-icon><v-icon>mdi-account-plus</v-icon></v-list-item-icon>
          <v-list-item-content
            >eine Zweitbeurteilung übernehmen (in der
            Liste)</v-list-item-content
          >
        </v-list-item>
        <v-list-item v-if="$_hasRole('teacher')">
          <v-list-item-icon><v-icon>mdi-trash-can</v-icon></v-list-item-icon>
          <v-list-item-content
            >dich von einer Zweitbeurteilung zurückziehen</v-list-item-content
          >
        </v-list-item>
      </v-list>
    </template>
    <template slot="extension" v-if="$_hasRole('staff')">
      <v-tabs
        :align-with-title="!$vuetify.breakpoint.xs"
        slider-color="white"
        background-color="success darken-1"
      >
        <v-tab :to="{ name: 'Thesis' }">Meine </v-tab>
        <v-tab :to="{ name: 'ThesisList' }">Alle</v-tab>
      </v-tabs>
    </template>
    <router-view></router-view>
  </Page>
</template>

<script>
export default {
  data() {
    return {
      search: "",
    };
  },
};
</script>
